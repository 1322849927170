<template>
    <ModalBox :is-active.sync="isModalActive">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.users.webmasters.modal.bindUser.header.title`) }}
            </h5>
        </template>
        <b-field expanded>
            <div class="columns is-variable is-1">
                <div class="column">
                    <FSelect
                        :value="selectedAdminIds"
                        :get-data-vuex="getAdmins"
                        :selectable="() => selectedAdminIds.length < 1"
                        :placeholder="$t(`admin.users.webmasters.modal.bindUser.body.labels.admin`)"
                        field="login"
                        multiple
                        :loading="isLocalLoading"
                        @select="onSelectAdmin">
                        <template #text="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                        <template #selected-option="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                    </FSelect>
                </div>
                <div
                    v-if="removable"
                    class="column is-narrow">
                    <b-button
                        type="is-danger is-light"
                        icon-right="times"
                        @click="unbindAdmin">
                    </b-button>
                </div>
            </div>
        </b-field>
        <template #footer>
            <div class="container">
                <div class="columns is-pulled-right">
                    <div class="buttons column is-4">
                        <b-button
                            :disabled="isAlreadyBind || isLocalLoading"
                            type="is-success"
                            @click="bindAdmin">
                            <template v-if="isAlreadyBind">
                                {{ $t("admin.users.common.messages.bind.alreadyBind") }}
                                <span
                                    :key="idx"
                                    v-for="(item, idx) in admin">
                                    {{ item.login }}
                                </span>
                            </template>
                            <template v-else>
                                <template v-if="selectedAdmins.length">
                                    {{ $t("common.buttons.bind") }}
                                    <span
                                        :key="idx"
                                        v-for="(item, idx) in selectedAdmins">
                                        {{
                                            item.login
                                        }}<template v-if="selectedAdmins.length - 1 !== idx">,</template>
                                    </span>
                                </template>
                                <template v-else>
                                    {{ $t("admin.users.webmasters.modal.bindUser.body.labels.bindToSelf") }}
                                </template>
                            </template>
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { Modal } from "@core/mixins";
  import { UsersFilters } from "@core/mixins/usersFilter";
  import { GET_ADMINS_LIST, GET_INITIAL_ADMINS } from "@core/store/action-constants";
  import { EMPTY_ADMINS_LIST, UPDATE_ADMINS_FILTERS, UPDATE_ADMINS_LIST_PAGINATION } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "ModalBindUserToAdmin",
    mixins: [Modal, UsersFilters],
    components: {
      ModalBox,
      FSelect,
      UserOutput
    },

    props: {
      method: {
        // Метод, которые будет использоваться для бинда админа
        // (потому что у разных типов пользователей могут быть разные метода)
        type: Function,
        required: true
      },
      deleteMethod: {
        type: Function,
        required: true
      },
      userId: {
        type: String,
        required: true
      },
      userLogin: {
        type: String,
        required: true
      },
      admin: {
        type: Array,
        default: () => []
      },
      role: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        selectedAdminIds: this.admin.map(item => item.id),
        selectedAdmins: this.admin
      };
    },

    computed: {
      ...mapState("admin", {
        adminUserIdState: state => state.profile.user.id
      }),

      ...mapState("admin/users/adminsList", {
        adminsList: state => state.data
      }),

      adminIds () {
        return this.selectedAdminIds.length !== 0 ? this.selectedAdminIds : [this.adminUserIdState];
      },

      removable () {
        return this.admin[0]?.id != null;
      },

      isAlreadyBind () {
        const adminIsd = this.admin.map(item => item.id);
        const arrIds = this.selectedAdminIds.map(item => adminIsd.includes(item));
        if (this.selectedAdminIds.length < this.admin.length || this.selectedAdminIds.length === 0) {
          return false;
        } else return arrIds.every(item => item);
      },
  
      isLocalLoading () {
        return this.isLoading || this.$wait(`admin/users/adminsList/${ GET_INITIAL_ADMINS }`);
      }
    },

    methods: {
      ...mapMutations("admin/users/adminsList", [
        UPDATE_ADMINS_LIST_PAGINATION,
        UPDATE_ADMINS_FILTERS,
        EMPTY_ADMINS_LIST
      ]),

      ...mapActions("admin/users/adminsList", [
        GET_ADMINS_LIST,
        GET_INITIAL_ADMINS
      ]),

      unbindAdmin () {
        const title = this.$t(`admin.users.webmasters.confirm.${ this.role === "webmasters" ? "isBoundWeb" : "isBoundRekl" }`);

        this.$confirm("", title, {
          confirmButtonText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelButtonText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "success",
          cancelButtonClass: "button",
          confirmButtonClass: "button is-success is-light"
        }).then(async () => {
          try {
            await this.deleteMethod(this.userId);
            this.emitUpdate();
            this.closeModal();
            this.toastSuccess(this.$t("admin.users.common.messages.unbind.success"));
          } catch (err) {
            this.toastFail(this.$t("admin.users.common.messages.unbind.fail"), err);
          }
        });
      },

      async bindAdmin () {
        const { userId, adminIds, userLogin } = this;

        try {
          await this.method({ userId, adminIds });
          this.toastSuccess(this.$t(`admin.users.${ this.role }.messages.bind.success`, { userLogin }));
          this.emitUpdate();
          this.closeModal();
        } catch (err) {
          this.toastFail(this.$t(`admin.users.${ this.role }.messages.bind.fail`, { userLogin }), err);
        }
      },
  
      async getAdmins (label = "") {
        this.UPDATE_ADMINS_LIST_PAGINATION();
        this.UPDATE_ADMINS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });

        if (this.adminsList?.items?.length > 0 || !this.selectedAdminIds) {
          await this.GET_ADMINS_LIST();
        } else {
          await this.GET_INITIAL_ADMINS(this.selectedAdminIds.length !== 0 ? this.selectedAdminIds : null);
        }
    
        return this.adminsList;
      },
  
      onSelectAdmin (option) {
        const arrayAdmins = Array.isArray(option) ? option : [option];
        if (arrayAdmins[0] === null) {
          this.selectedAdminIds = [];
          this.selectedAdmins = [];
        } else {
          this.selectedAdminIds = arrayAdmins.map(item => item.id);
          this.selectedAdmins = arrayAdmins;
        }
      }
    },

    beforeDestroy () {
      this.EMPTY_ADMINS_LIST();
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .modal-card, .modal-card-body {
            overflow: visible;
        }
    }
</style>
