<template>
    <div>
        <DetailsFilters
            :filters.sync="filters"
            :is-loading="isLoading"
            @clear="clearFilters"
            @update="update">
        </DetailsFilters>
        <DetailsTable
            :data="statistic"
            :is-loading="isLoading"
            :currency="currency_">
        </DetailsTable>
    </div>
</template>

<script>
  import { momentInst } from "@core/filters";
  import { FilteredComponent } from "@core/mixins";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import DetailsFilters from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/AdvertisersTableDetailsModalDetails/DetailsFilters";
  import DetailsTable from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/AdvertisersTableDetailsModalDetails/DetailsTable";
  
  export default {
    name: "AdvertisersTableDetailsModalDetails",
    mixins: [FilteredComponent],
    components: {
      DetailsTable,
      DetailsFilters
    },
    props: {
      advertiser: {
        type: Object,
        default: null
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        filters: {
          datepicker: {
            dateStart: momentInst().startOf("day").subtract(7, "day").toDate(),
            dateEnd: momentInst().endOf("day").toDate()
          },
          groups: ["webmaster", "offer"],
          webmasterId: null,
          currency: this.$store.state.auth.preferredCurrency,
          offerId: null
        },
        currency_: this.$store.state.auth.preferredCurrency
      };
    },
    computed: {
      ...mapState({
        statistic: state => state.admin.statistic.statistics
      }),

      isLoading () {
        return this.$wait(`admin/${ GET_STATISTIC }`);
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_STATISTIC
      ]),

      update () {
        const { datepicker, groups, ...filters } = this.filters;
        const queryGroups = groups.map(item => item?.toUpperCase()).filter(Boolean);

        this.GET_STATISTIC({
          groups: queryGroups,
          filters: { ...datepicker, ...filters, advertiserId: this.advertiser?.id }
        });
        this.currency_ = filters.currency;
      }
    }
  };
</script>

<style scoped>

</style>
