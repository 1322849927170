<template>
    <ModalBox
        :is-active.sync="isModalActive "
        :on-cancel="currentFile !== 'list' ? () => closeModalOnForm() : null">
        <template #header>
            <div class="columns is-vcentered">
                <div
                    v-if="currentFile !== 'list'"
                    class="is-clickable"
                    @click="backToList">
                    <b-icon
                        icon="angle-left"
                        size="is-small"></b-icon>
                    {{ $t("common.entity.callCenters.labels.back") }}
                </div>
                <h5 class="subtitle is-narrow column">
                    {{ $t(`common.entity.callCenters.labels.${ currentFile }`) }}
                </h5>
            </div>
        </template>
        
        <CallCentersList
            v-if="currentFile === 'list'"
            :get-call-centers="() => getCallCenters()"
            :is-loading="loading"
            :per-page="perPage"
            @openForm="openForm($event)"
            @updatePerPage="perPage += 12">
        </CallCentersList>
        <AddCallCenter
            v-if="currentFile === 'create'"
            :advertiser-id="advertiserId"
            @changeFile="changeFile($event)">
        </AddCallCenter>
        <EditCallCenter
            v-if="currentFile === 'edit'"
            :editable-call-center="editableCallCenter"
            @changeFile="changeFile($event)">
        </EditCallCenter>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import { Modal } from "@core/mixins";
  import { GET_CALLCENTERS } from "@core/store/action-constants";
  import { mapActions } from "vuex";
  import AddCallCenter from "@/components/Admin/Users/AdvertisersTab/CallСenters/AddCallCenter";
  import CallCentersList from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersList";
  import EditCallCenter from "@/components/Admin/Users/AdvertisersTab/CallСenters/EditCallCenter";
  
  export default {
    name: "CallCenters",
    mixins: [Modal],
    components: {
      AddCallCenter,
      EditCallCenter,
      CallCentersList,
      ModalBox
    },
    props: {
      advertiserId: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        currentFile: "list",
        editableCallCenter: null,
        currentPage: 1,
        perPage: 12,
        loading: false
      };
    },
    methods: {
      ...mapActions("admin", [
        GET_CALLCENTERS
      ]),
      async getCallCenters () {
        const params = {
          filters: {
            advertisers: [this.advertiserId]
          },
          limit: this.perPage,
          offset: (this.currentPage - 1) * this.perPage
        };
        this.loading = true;
        await this.GET_CALLCENTERS(params);
        this.loading = false;
      },
      changeFile (event) {
        this.currentFile = event;
        this.getCallCenters();
      },
      openForm (callCenter) {
        this.editableCallCenter = callCenter;
        this.currentFile = callCenter ? "edit" : "create";
      },
      backToList () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.callCenters.modals.backToList.title"),
          message: this.$t("common.entity.callCenters.modals.backToList.message"),
          confirmText: this.$t("common.entity.callCenters.modals.backToList.confirmButtonText"),
          cancelText: this.$t("common.entity.callCenters.buttons.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: () => {
            this.currentFile = "list";
            this.editableCallCenter = null;
          }
        });
      },
      closeModalOnForm () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.callCenters.modals.closeModalForm.title"),
          message: this.$t(`common.entity.callCenters.modals.closeModalForm.${ this.currentFile }`),
          confirmText: this.$t("common.entity.callCenters.modals.closeModalForm.confirmButtonText"),
          cancelText: this.$t("common.entity.callCenters.buttons.cancelButtonText"),
          type: "is-danger is-light",
          onConfirm: () => {
            this.isModalActive = false;
            this.currentFile = "list";
          }
        });
      }
    },
    watch: {
      advertiserId () {
        this.getCallCenters();
        this.perPage = 12;
      }
    }
  };

</script>

<style lang="scss" scoped>
    .modal::v-deep {
        .animation-content {
            max-width: 100% !important;
        }

        .modal-card {
            max-width: 100%;
            width: auto;

            .modal-card-foot {
                padding: 10px;
            }
        }
    }
</style>
