<template>
    <ModalBox
        v-if="advertiser"
        width="90%"
        :is-active.sync="isModalActive">
        <template #header>
            <h5 class="is-5 title">
                {{ $t(`admin.users.advertisers.modal.details.header.title`) }} {{ advertiser.login }}
            </h5>
        </template>
        
        <AdvertisersTableDetailsModalDetails :advertiser="advertiser"></AdvertisersTableDetailsModalDetails>
        <div class="column"></div>
        <AdvertisersTableDetailsModalRates :advertiser="advertiser"></AdvertisersTableDetailsModalRates>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import { Modal } from "@core/mixins";
  import AdvertisersTableDetailsModalDetails from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/AdvertisersTableDetailsModalDetails";
  import AdvertisersTableDetailsModalRates from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/AdvertisersTableDetailsModalRates";
  
  export default {
    name: "AdvertisersTableDetailsModal",
    mixins: [Modal],
    components: {
      ModalBox,
      AdvertisersTableDetailsModalDetails,
      AdvertisersTableDetailsModalRates
    },
    props: {
      advertiser: {
        type: Object,
        default: null
      }
    }
  };
</script>

<style lang="scss" scoped>
    .modal {
        ::v-deep {
            .animation-content {
                max-width: 1600px !important;
                width: calc(100vw - 40px);
            }
        
            .modal-card {
                max-width: 100%;
                width: auto;
            
                .modal-card-foot {
                    padding: 7.5px;
                }
            }
        }
    }
</style>
