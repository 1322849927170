<template>
    <div>
        <b-field
            :label="title"
            class="mb-0">
            <div class="columns">
                <div class="column">
                    <slot
                        class="column"
                        name="params"></slot>
                </div>
            </div>
        </b-field>
        <slot name="times"></slot>
        <slot name="footer"></slot>
    </div>
</template>

<script>
  export default {
    name: "FormTimetableDayWrapper",
    props: {
      title: {
        type: String,
        default: null
      }
    }
  };
</script>

<style scoped>

</style>
