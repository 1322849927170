<template>
    <div class="columns is-multiline is-align-items-flex-end">
        <div class="column is-2">
            <FField :label="$t(`admin.users.advertisers.filters.labels.login`)">
                <FInput
                    v-model="loginOrIntId"
                    :placeholder="$t('admin.users.advertisers.filters.labels.login')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.users.advertisers.filters.labels.blocked`)">
                <FSelect
                    v-model="isBlocked"
                    :get-data="getIsBlocked"
                    :placeholder="$t(`admin.users.advertisers.filters.values.all`)"
                    field="label"
                    prop="value">
                </FSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.users.advertisers.filters.labels.approve`)">
                <FSelect
                    v-model="isApproved"
                    :get-data="getIsApproved"
                    :placeholder="$t(`admin.users.advertisers.filters.values.all`)"
                    field="label"
                    prop="value">
                </FSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <FField :label="$t(`admin.users.advertisers.filters.labels.email`)">
                <FInput
                    v-model="email"
                    :placeholder="$t('admin.users.advertisers.filters.labels.email')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.users.advertisers.filters.labels.emailConfirmed`)">
                <FSelect
                    v-model="isEmailConfirmed"
                    :get-data="getIsEmailConfirmed"
                    :placeholder="$t(`admin.users.advertisers.filters.values.all`)"
                    field="label"
                    prop="value">
                </FSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <FField :label="$t(`admin.users.advertisers.filters.labels.telegram`)">
                <FInput
                    v-model="telegram"
                    :placeholder="$t('admin.users.advertisers.filters.labels.telegram')">
                </FInput>
            </FField>
        </div>
        <div class="column is-2">
            <b-field :label="$t(`admin.users.webmasters.filters.labels.manager`)">
                <FSelect
                    v-model="manager"
                    field="login"
                    :get-data-vuex="getAdmins"
                    :min-required-length="1"
                    :placeholder="$t(`admin.users.webmasters.filters.labels.manager`)">
                    <template #text="option">
                        <UserOutput :user="option.option"></UserOutput>
                    </template>
                    <template #selected-option="option">
                        <UserOutput :user="option.option"></UserOutput>
                    </template>
                </FSelect>
            </b-field>
        </div>
    </div>
</template>

<script>
  import UserOutput from "@/components/Common/UserOutput";
  import { UsersFilters } from "@core/mixins";
  import {
    GET_ADMINS_LIST,
    GET_ADVERTISERS,
    GET_APPROVED,
    GET_BLOCKED,
    GET_EMAIL_CONFIRMED,
    GET_INITIAL_ADMINS
  } from "@core/store/action-constants";
  import {
    UPDATE_ADMINS_DICTIONARY,
    UPDATE_ADMINS_FILTERS,
    UPDATE_ADMINS_LIST_PAGINATION,
    UPDATE_ADVERTISERS_FILTERS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import FSelect from "@/components/Common/FSelect";
  import FField from "@/components/Common/FField";
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "AdvertisersFilters",
    mixins: [UsersFilters],
    components: {
      FSelect,
      FInput,
      FField,
      UserOutput
    },

    computed: {
      ...mapFields("admin/users/advertisers", {
        fields: [
          "loginOrIntId",
          "isBlocked",
          "isApproved",
          "email",
          "isEmailConfirmed",
          "telegram",
          "manager"
        ],
        base: "filters",
        action: UPDATE_ADVERTISERS_FILTERS
      }),

      ...mapState("admin/users/advertisers",{
        advertisersFilters: state => state.filters,
        blockedList: state => state.blockedList.data,
        emailConfirmedList: state => state.emailConfirmedList.data,
        approvedList: state => state.approvedList.data,
        adminsList: state => state.adminsList.data
      })
    },

    methods: {
      ...mapActions("admin", [UPDATE_ADMINS_DICTIONARY]),

      ...mapActions("admin/users/advertisers", {
        GET_ADVERTISERS,
        GET_BLOCKED: `blockedList/${ GET_BLOCKED }`,
        GET_APPROVED: `approvedList/${ GET_APPROVED }`,
        GET_EMAIL_CONFIRMED: `emailConfirmedList/${ GET_EMAIL_CONFIRMED }`,
        GET_ADMINS_LIST: `adminsList/${ GET_ADMINS_LIST }`,
        GET_INITIAL_ADMINS: `adminsList/${ GET_INITIAL_ADMINS }`
      }),

      ...mapMutations("admin/users/advertisers", {
        UPDATE_ADMINS_LIST_PAGINATION: `adminsList/${ UPDATE_ADMINS_LIST_PAGINATION }`,
        UPDATE_ADMINS_FILTERS: `adminsList/${ UPDATE_ADMINS_FILTERS }`
      }),

      getIsApproved () {
        this.GET_APPROVED();
        return this.approvedList;
      },
      getIsBlocked () {
        this.GET_BLOCKED();
        return this.blockedList;
      },
      getIsEmailConfirmed () {
        this.GET_EMAIL_CONFIRMED();
        return this.emailConfirmedList;
      },

      async getAdmins (label = "") {
        this.UPDATE_ADMINS_LIST_PAGINATION();
        this.UPDATE_ADMINS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });
        if (this.adminsList?.items?.length > 0) {
          await this.GET_ADMINS_LIST();
        } else {
          await this.GET_INITIAL_ADMINS(this.$route.query.manager);
        }
        return this.adminsList;
      }
    }
  };
</script>
