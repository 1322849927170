<template>
    <b-dropdown
        ref="dropdownComment"
        :can-close="canClose && !isLoading"
        append-to-body
        aria-role="menu"
        position="is-bottom-left"
        trap-focus
        @active-change="onDropdownToggle($event)">
        <template #trigger>
            <b-icon
                class="ml-2"
                icon="pen"
                size="is-small"
                style="cursor: pointer"
                type="is-primary">
            </b-icon>
        </template>
        <b-dropdown-item
            :focusable="false"
            aria-role="menu-item"
            custom
            paddingless>
            <el-form
                class="comment-form px-4 py-2"
                @submit.prevent.native="changeComment()">
                <FInput
                    v-model="comment"
                    class="mb-8"
                    :disabled="isLoading"
                    maxlength="500"
                    minlength="0"
                    :rows="5"
                    placeholder="Comment"
                    type="textarea"
                    show-word-limit
                    @mousedown.native="canClose = false"
                    @mouseup.native="canClose = true">
                </FInput>
                <b-button
                    :loading="isLoading"
                    class="is-primary"
                    native-type="submit">
                    {{ $t("common.buttons.save") }}
                </b-button>
            </el-form>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "DropdownComment",
    components: { FInput },
    props: {
      value: {
        type: String,
        default: null
      },
      
      isLoading: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        comment: null,
        canClose: true
      };
    },

    methods: {
      onDropdownToggle (active) {
        if (active) {
          this.comment = this.value;
        }
      },

      changeComment () {
        this.$emit("update", this.comment);
      },

      toggle () {
        this.$refs.dropdownComment.toggle();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .comment-form {
        width: 300px;
    }

    ::v-deep {
        .el-textarea__inner {
            @apply p-2;
        }
    }
</style>
