<template>
    <PaymentModal
        :is-active.sync="isModalActive"
        :is-loading="isLoadingCreateIncome"
        :user-id="user"
        :with-requisites="false"
        @submit="createPayment"
        @close="closeModal"
        @update:user="getBalance">
        <template #user="{ modal }">
            <b-field
                :label="$t(`admin.finances.incomes.modal.body.advertiser.title`)"
                expanded>
                <FSelect
                    v-model="modal.user.id"
                    :get-data="getAdvertisers"
                    field="login"
                    :min-required-length="1"
                    :placeholder="$t(`admin.finances.incomes.modal.body.advertiser.label`)"
                    :disabled="user != null">
                    <template #text="{ option }">
                        <UserOutput :user="option"></UserOutput>
                    </template>
                    <template #selected-option="{ option }">
                        <UserOutput :user="option"></UserOutput>
                    </template>
                </FSelect>
            </b-field>
            <b-field
                v-if="balance != null"
                :label="$t(`admin.finances.incomes.modal.body.advertiser.balance`)">
                <b-button
                    class="has-text-weight-bold is-paddingless has-background-white is-12 column"
                    type="is-white">
                    <h5
                        :key="idx"
                        v-for="(item, idx) in balance"
                        class="title is-5 has-text-right mb-0">
                        {{ formatCurrency(
                            toFixed(item.balance.balance.value, 2),
                            item.balance.balance.currency.toUpperCase()
                        ) }}
                    </h5>
                </b-button>
            </b-field>
        </template>
    </PaymentModal>
</template>

<script>
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { formatCurrency, toFixed } from "@core/filters";
  import { Modal, UsersFilters } from "@core/mixins";
  import { CREATE_INCOME, GET_REQUISITES, GET_USER_BALANCE } from "@core/store/action-constants";
  import { SET_ADVERTISER_BALANCE, UPDATE_ADVERTISERS_DICTIONARY } from "@core/store/mutation-constants";
  import { mapActions, mapMutations } from "vuex";
  import PaymentModal from "@/components/Admin/Finances/PaymentModal";

  export default {
    name: "FinancesIncomesCreatePaymentModal",
    mixins: [Modal, UsersFilters],
    components: {
      PaymentModal,
      FSelect,
      UserOutput
    },
    props: {
      user: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        balance: null,
        balanceIsLoading: false
      };
    },

    computed: {
      isLoadingCreateIncome () {
        return this.$wait(`admin/${ CREATE_INCOME }`);
      }
    },

    methods: {
      ...mapActions("admin", [
        CREATE_INCOME,
        GET_USER_BALANCE,
        GET_REQUISITES,
        UPDATE_ADVERTISERS_DICTIONARY
      ]),

      ...mapMutations("admin", {
        SET_ADVERTISER_BALANCE
      }),

      async getBalance (value) {
        if (value?.id) {
          this.balanceIsLoading = true;
          this.balance = await this.GET_USER_BALANCE(value.id);
          this.balanceIsLoading = false;
        } else this.balance = null;
      },

      getAdvertisers (offset = 0, label = "", loginFilterType = "loginOrIntId") {
        if (this.user) {
          label = this.user;
          loginFilterType = "id";
        }

        return this.getUsersByActionName(this.UPDATE_ADVERTISERS_DICTIONARY, label, offset, loginFilterType);
      },

      async createPayment (modal) {
        const { amount, comment, paymentSystem, user: { id: userId } } = modal;
        try {
          await this.CREATE_INCOME({
            userId,
            amount,
            comment,
            currency: paymentSystem?.currency,
            paymentSystemId: paymentSystem.id || null
          });

          this.closeModal();
        } catch (e) {
          throw new Error(e);
        }
      },

      formatCurrency,

      toFixed
    }
  };
</script>

<style scoped></style>
