<template>
    <TablePlaceholder :data="data">
        <CustomizableTable
            ref="customizableTable"
            :data-manager="dataManager"
            :fields="fields"
            :sort-order="sortOrder"
            :is-loading="isLoading">
            <template #tableHeader>
                <StatisticTableHeader :styling="headerStyling">
                    <template slot-scope="{ fieldName: name }">
                        <div class="table-header">
                            <label>
                                {{ $t(`admin.users.advertisers.modal.details.body.individualRates.labels.${ name }`) }}
                            </label>
                        </div>
                    </template>
                </StatisticTableHeader>
                <VuetableRowHeader></VuetableRowHeader>
            </template>
        </CustomizableTable>
    </TablePlaceholder>
</template>

<script>
  import CustomizableTable from "@/components/Common/Table/CustomizableTable";
  import StatisticTableHeader from "@/components/Common/Table/StatisticTableHeader";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import StatisticTableViewNumber from "@/components/Common/Table/View/StatisticTableViewNumber";
  import StatisticTableViewOfferOutput from "@/components/Common/Table/View/StatisticTableViewOfferOutput";
  import StatisticTableViewUserOutput from "@/components/Common/Table/View/StatisticTableViewUserOutput";
  import VuetableMixin from "@/components/Common/Table/VuetableMixin";
  import { formatCurrency } from "@core/filters";
  import { GET_ADVERTISER_RATES } from "@core/store/action-constants";
  import _orderBy from "lodash/orderBy";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "RatesTable",

    mixins: [VuetableMixin],

    components: { StatisticTableHeader, TablePlaceholder, CustomizableTable },

    created () {
      this.GET_ADVERTISER_RATES();
    },

    data () {
      return {
        sortOrder: []
      };
    },

    computed: {
      ...mapState("admin/users/advertisers/detail/rates", ["data"]),

      fields () {
        if (!this.data) return [];

        const common = {
          titleClass: "has-text-left has-text-grey-light",
          dataClass: "has-text-left"
        };

        return [
          {
            ...common,
            name: StatisticTableViewUserOutput,
            groupName: "individualRate",
            label: "individualRate.webmaster",
            sortField: "individualRate.webmaster.login",
            title: this.$t("admin.users.advertisers.modal.details.body.individualRates.labels.webmaster")
          },
          {
            ...common,
            name: StatisticTableViewOfferOutput,
            groupName: "individualRate",
            label: "individualRate.offer",
            sortField: "individualRate.offer.detail.name",
            title: this.$t("admin.users.advertisers.modal.details.body.individualRates.labels.offer"),
            formatter: ({ intId, detail: { name } }) => ({ intId, name })
          },
          {
            ...common,
            name: StatisticTableViewNumber,
            groupName: "individualRate",
            label: "individualRate.totalWriteOff",
            sortField: "individualRate.totalWriteOff.value",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.advertisers.modal.details.body.individualRates.labels.totalWriteOff"),
            formatter: value => formatCurrency(value.value, value.currency)
          },
          {
            ...common,
            name: StatisticTableViewNumber,
            groupName: "individualRate",
            label: "individualRate.individualWriteOff",
            sortField: "individualRate.individualWriteOff.value",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.advertisers.modal.details.body.individualRates.labels.individualWriteOff"),
            formatter: value => {
              if (value) {
                return formatCurrency(value.value, value.currency);
              } return null;
            }
          },
          {
            ...common,
            name: StatisticTableViewNumber,
            groupName: "individualRate",
            label: "individualRate.reward",
            sortField: "individualRate.reward.value",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.advertisers.modal.details.body.individualRates.labels.reward"),
            formatter: value => {
              if (value) {
                return formatCurrency(value.value, value.currency);
              } return null;
            }
          },
          {
            ...common,
            name: StatisticTableViewNumber,
            groupName: "individualRate",
            label: "individualRate.commission",
            sortField: "individualRate.commission",
            dataClass: "has-text-right",
            width: "1%",
            title: this.$t("admin.users.advertisers.modal.details.body.individualRates.labels.commission"),
            formatter: value => formatCurrency(value.value, value.currency)
          }
        ];
      },

      isLoading () {
        return this.$wait(`admin/users/advertisers/detail/rates/${ GET_ADVERTISER_RATES }`);
      }
    },

    methods: {
      ...mapActions("admin/users/advertisers/detail/rates", [GET_ADVERTISER_RATES]),

      dataManager (sortOrder) {
        const items = this.data.items.map(individualRate => {
          const { individualWriteOff, totalWriteOff, reward } = individualRate;
          const writeOff = individualWriteOff ?? totalWriteOff;

          return {
            individualRate: {
              ...individualRate,
              commission: writeOff - reward
            }
          };
        });

        return _orderBy(items, sortOrder.map(el => el.sortField), sortOrder.map(el => el.direction));
      },

      headerStyling (name) {
        switch (name) {
          case "individualRate":
            return {
              "color": "black",
              "background-color": "#F7F7F7"
            };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .sort-icon {
            line-height: 1.5;
        }

        .tippy {
            & + span {
                .sort-icon {
                    line-height: 2.4;
                }
            }
        }

        ul {
            li {
                &:not(:first-child) {
                    padding-top: 0.2em;
                }

                div {
                    .flag-icon {
                        margin-right: 0.4em;
                    }
                }
            }
        }

        table {
            thead, tbody {
                background-color: white;
            }

            thead {
                tr {
                    &:first-child {
                        th {
                            padding: 0;

                            > div {
                                display: flex;
                                padding: 20px;
                                align-items: center;
                                justify-content: space-between;

                                label {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
      .card {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      }
    }

    .icon-btn {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
</style>
