<template>
    <component
        :is="tag"
        :class="`hover:${ coloredStatus[0] }`"
        class="dropdown-wrapper"
        @click="$emit('click', $event)">
        <slot name="option">
            <div class="media">
                <div class="media-left">
                    <slot name="option-icon">
                        <span
                            :class="coloredStatus[0]"
                            class="flex items-center justify-center rounded-full w-7 h-7">
                            <FSvgIcon
                                class="dropdown-icon"
                                :icon="icon"
                                :color="coloredStatus[1]">
                            </FSvgIcon>
                        </span>
                    </slot>
                </div>
                <div class="media-content">
                    <slot name="option-content">
                        <h3>
                            <slot
                                v-if="$slots.contentHeader"
                                name="option-content-header"></slot>
                            <slot v-else></slot>
                        </h3>
                        <small>
                            <slot name="option-content-subheader"></slot>
                        </small>
                    </slot>
                </div>
            </div>
        </slot>
    </component>
</template>

<script>
  // В минимальном виде в компонент нужно передать только
  // type - чтобы указать цвет
  // icon - для выбора иконки
  // pack - для выбора пака иконок

  // Для более тонкой настрокий есть следующие слоты:
  // option - родительский слот, полностью перезаписывает компонент
  // option-icon - иконка
  // option-content - перезапись слота контента
  // option-content-header - заголовок
  // option-content-subheader - подзаголовок

  // События
  // @click
  import FSvgIcon from "@/components/Common/FSvgIcon";
  export default {
    name: "DropdownOption",
    components: { FSvgIcon },
    props: {
      type: {
        type: String,
        default: null
      },
      tag: {
        type: String,
        default: "div"
      },
      icon: {
        type: String,
        default: null
      }
    },
      
    computed: {
      coloredStatus () {
        switch (this.type) {
          case "is-warning is-light" : return ["bg-yellow-100", "#fbbf24"];
          case "is-success is-light" : return ["bg-green-100", "#34d399"];
          case "is-danger is-light" : return ["bg-red-100", "#ef4444"];
          case "is-info is-light" : return ["bg-blue-100", "#3b82f6"];
          case "is-warning" : return ["bg-yellow-400", "#fef3c7"];
          case "is-success" : return ["bg-green-400", "#d1fae5"];
          case "is-danger" : return ["bg-red-500", "#fee2e2"];
          case "is-info" : return ["bg-blue-500", "#dbeafe"];
          default : return ["bg-gray-200", "#6b7280"];
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .media {
        &-content {
            display: flex;
            font-size: 1em;
        }
    }

    .is-light {
        .media {
            &-content {
                color: black !important;
            }
        }
    }

    .dropdown {
        &-wrapper {
            cursor: pointer;
            padding: 0 1em;
            text-align: center;
            white-space: nowrap;

            .media {
                align-items: center;
            }
        }

        &-icon {
            @apply inline-flex items-center justify-center w-4 h-4;
        }
    }
</style>
