<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            :model="{ email: form.email, telegram: form.telegram }"
            @submit="edit"
            @close="close">
            <template #header>
                <h5 class="title is-5">
                    {{ $t(`admin.users.advertisers.modal.edit.header.title`) }}
                </h5>
            </template>
            <AdvertiserProfile
                ref="ruleForm"
                :user.sync="localForm"
                is-super-edit>
            </AdvertiserProfile>
            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-info is-light"
                                expanded
                                @click="openPasswordModal">
                                {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                            </b-button>
                        </div>
                        <div class="column">
                            <b-button
                                expanded
                                native-type="submit"
                                class="is-success is-light">
                                {{ $t(`admin.users.advertisers.modal.edit.buttons.save`) }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
            <b-loading
                v-model="isLoading"
                :is-full-page="false">
            </b-loading>
        </ModalBox>
        <PasswordModal
            :is-active.sync="isPasswordModalActive"
            :is-loading="isPasswordLoading"
            :password.sync="password"
            @submit="changePassword"
            @closed="emptyPasswordState">
        </PasswordModal>
    </div>
</template>

<script>
  import PasswordModal from "@/components/Admin/Users/common/PasswordModal";
  import AdvertiserProfile from "@/components/Common/Advertiser/AdvertiserProfile";
  import ModalBox from "@/components/Common/ModalBox";
  import {
    EDIT_ADVERTISER,
    EDIT_PASSWORD,
    GET_ADVERTISER,
    GET_ADVERTISERS_WITH_BALANCE
  } from "@core/store/action-constants";
  import {
    SET_EMPTY,
    SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE,
    UPDATE_ADVERTISERS_EDIT_FORM,
    UPDATE_MODAL,
    UPDATE_PASSWORD_FORM
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "AdvertisersEditModal",
    
    components: {
      ModalBox,
      AdvertiserProfile,
      PasswordModal
    },
    
    data () {
      return {
        localForm: null
      };
    },

    computed: {
      ...mapState("admin/users/advertisers/edit", [
        "advertiserId",
        "form"
      ]),

      ...mapFields("admin/users/advertisers/edit/password", {
        fields: [
          "isPasswordModalActive"
        ],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapFields("admin/users/advertisers/edit/password", {
        fields: [
          "password"
        ],
        base: "form",
        action: UPDATE_PASSWORD_FORM
      }),

      isActive: {
        get () {
          return this.$store.state.admin.users.advertisers.edit.isModalActive;
        },

        set (value) {
          this.SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE(value);
        }
      },

      isLoading () {
        return this.$wait(`admin/users/advertisers/edit/${ EDIT_ADVERTISER }`);
      },

      isPasswordLoading () {
        return this.$wait(`admin/users/advertisers/edit/password/${ EDIT_PASSWORD }`);
      }
    },
    
    methods: {
      ...mapActions("admin/users/advertisers", [
        GET_ADVERTISERS_WITH_BALANCE
      ]),

      ...mapActions("admin/users/advertisers/edit", [
        GET_ADVERTISER,
        EDIT_ADVERTISER
      ]),

      ...mapActions("admin/users/advertisers/edit/password", [
        EDIT_PASSWORD
      ]),

      ...mapMutations("admin/users/advertisers/edit", [
        SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE,
        UPDATE_ADVERTISERS_EDIT_FORM,
        SET_EMPTY
      ]),

      ...mapMutations("admin/users/advertisers/edit/password", {
        emptyPasswordState: SET_EMPTY
      }),

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      async changePassword () {
        try {
          await this.EDIT_PASSWORD();
          this.emptyPasswordState();

          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.password.messages.success"),
            type: "is-success"
          });
        } catch (error) {
          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.password.messages.fail"),
            type: "is-danger"
          });
        }
      },

      async edit () {
        try {
          await this.EDIT_ADVERTISER();

          this.close();
          this.GET_ADVERTISERS_WITH_BALANCE();
          this.$buefy.toast.open({
            message: this.$t("admin.users.advertisers.modal.toast.success"),
            type: "is-success"
          });
        } catch ({ errors }) {
          if (errors && Array.isArray(errors)) {
            const { message } = errors[0];

            this.$buefy.toast.open({
              message: this.$t(`admin.users.advertisers.modal.error.${ message }`, { email: this.form.email }),
              type: "is-danger"
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("admin.users.advertisers.modal.toast.fail"),
              type: "is-danger"
            });
          }
        }
      },

      close () {
        if (!this.isPasswordModalActive) {
          this.SET_EMPTY();
          this.localForm = null;
        }
      }
    },

    watch: {
      async advertiserId (value) {
        if (value) {
          await this.GET_ADVERTISER();
          this.localForm = _cloneDeep(this.form);
        }
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_ADVERTISERS_EDIT_FORM(value);
        }
      },

      isPasswordModalActive (value) {
        this.isActive = !value;
      }
    }
  };
</script>

<style></style>
