<template>
    <div class="wrapper">
        <AdvertisersFilters></AdvertisersFilters>
        <AdvertisersTable></AdvertisersTable>
        <AdvertisersEditModal></AdvertisersEditModal>
    </div>
</template>

<script>
  import AdvertisersEditModal from "@/components/Admin/Users/AdvertisersTab/AdvertisersEditModal";
  import AdvertisersFilters from "@/components/Admin/Users/AdvertisersTab/AdvertisersFilters.vue";
  import AdvertisersTable from "@/components/Admin/Users/AdvertisersTab/AdvertisersTable.vue";
  import { prepareBooleanString } from "@core/helpers/prepareBooleanString";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import { GET_ADVERTISERS_WITH_BALANCE } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_ADVERTISERS_FILTERS, UPDATE_ADVERTISERS_PAGINATION } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "UsersAdvertisers",
    
    mixins: [DebounceUpdateByWatchedParams],
    
    components: {
      AdvertisersFilters,
      AdvertisersTable,
      AdvertisersEditModal
    },

    permissions: [
      "ADVERTISERS.LIST.ALL",
      "ADVERTISERS.LIST.OWN",
      "ADVERTISERS.LIST.UNBIND"
    ],

    created () {
      const {
        page,
        perPage,
        loginOrIntId,
        isBlocked,
        isApproved,
        isEmailConfirmed,
        manager
      } = this.$route.query;

      if (page) {
        this.page = Number(page);
      }

      if (perPage) {
        this.perPage = Number(perPage);
      }

      this.UPDATE_ADVERTISERS_FILTERS({
        ...loginOrIntId && { loginOrIntId },
        ...isBlocked && { isBlocked: prepareBooleanString(isBlocked) },
        ...isApproved && { isApproved: prepareBooleanString(isApproved) },
        ...isEmailConfirmed && { isEmailConfirmed: prepareBooleanString(isEmailConfirmed) },
        ...manager && { manager }
      });
    },

    computed: {
      ...mapFields("admin/users/advertisers", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_ADVERTISERS_PAGINATION
      }),

      ...mapState("admin/users/advertisers", {
        updateParams: ({ pagination, filters }) => ({ pagination, filters })
      })
    },

    methods: {
      ...mapActions("admin/users/advertisers", [
        GET_ADVERTISERS_WITH_BALANCE,
        SET_EMPTY
      ]),

      ...mapMutations("admin/users/advertisers", [
        UPDATE_ADVERTISERS_FILTERS
      ]),

      async updated () {
        await this.GET_ADVERTISERS_WITH_BALANCE();
  
        const query = {
          ...this.updateParams.filters,
          ...this.updateParams.pagination
        };
  
        this.$router.push({
          query
        }).catch(_ => {
        });
      }
    },
    
    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped lang="scss">
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
</style>
