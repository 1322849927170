<template>
    <div class="columns is-multiline">
        <div class="column is-2-desktop is-3">
            <FSelect
                v-model="webmaster"
                :get-data-vuex="getWebmasters"
                :min-required-length="1"
                :placeholder="$t(`admin.users.advertisers.modal.details.header.filters.labels.webmaster`)"

                field="login"
                multiple>
                <template #text="{ option }">
                    <UserOutput :user="option"></UserOutput>
                </template>
                <template #selected-option="{ option }">
                    <UserOutput :user="option"></UserOutput>
                </template>
            </FSelect>
        </div>
        <div class="column is-2-desktop is-3">
            <b-field>
                <FSelect
                    v-model="offer"

                    :get-data="getOffers"
                    :min-required-length="1"
                    :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.offer`)"
                    multiple>
                    <template #text="option">
                        <OfferOutput :offer="option.option"></OfferOutput>
                    </template>
                    <template #selected-option="option">
                        <OfferOutput :offer="option.option"></OfferOutput>
                    </template>
                </FSelect>
            </b-field>
        </div>
    </div>
</template>

<script>
  import OfferOutput from "@/components/Common/OfferOutput";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { Filters, OffersFilters, UsersFilters } from "@core/mixins";
  import { GET_INITIAL_WEBMASTERS, GET_WEBMASTERS_LIST, UPDATE_OFFERS_DICTIONARY } from "@core/store/action-constants";
  import {
    SET_EMPTY_FILTERS,
    UPDATE_FILTERS,
    UPDATE_WEBMASTERS_FILTERS,
    UPDATE_WEBMASTERS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "RatesFilters",

    mixins: [Filters, OffersFilters, UsersFilters],

    components: {
      FSelect,
      OfferOutput,
      UserOutput
    },

    props: {
      advertiser: {
        type: Object,
        default: null
      }
    },

    computed: {
      ...mapFields("admin/users/advertisers/detail/rates", {
        fields: ["offer", "webmaster"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("admin/users/advertisers/detail/rateWebmastersList", {
        webmastersList: state => state.data
      })
    },

    methods: {
      ...mapMutations("admin/users/advertisers/detail", {
        UPDATE_WEBMASTERS_LIST_PAGINATION: `rateWebmastersList/${ UPDATE_WEBMASTERS_LIST_PAGINATION }`,
        UPDATE_WEBMASTERS_FILTERS: `rateWebmastersList/${ UPDATE_WEBMASTERS_FILTERS }`
      }),

      ...mapActions("admin", [
        UPDATE_OFFERS_DICTIONARY
      ]),

      ...mapActions("admin/users/advertisers/detail", {
        SET_EMPTY_FILTERS: `rates/${ SET_EMPTY_FILTERS }`,
        GET_WEBMASTERS_LIST: `rateWebmastersList/${ GET_WEBMASTERS_LIST }`,
        GET_INITIAL_WEBMASTERS: `rateWebmastersList/${ GET_INITIAL_WEBMASTERS }`
      }),

      getOffers (offset = 0, label = "") {
        return this.getOffersByActionName(this.UPDATE_OFFERS_DICTIONARY, label, offset);
      },

      async getWebmasters (label = "") {
        this.UPDATE_WEBMASTERS_LIST_PAGINATION();
        this.UPDATE_WEBMASTERS_FILTERS({
          loginOrIntId: label.length > 0 ? label : null,
          webmastersByAdvertiser: this.advertiser?.id
        });
        await this.GET_WEBMASTERS_LIST();

        return this.webmastersList;
      }
    },

    beforeDestroy () {
      this.SET_EMPTY_FILTERS();
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        flex-basis: 240px;
    }
</style>
