<template>
    <el-dropdown
        trigger="click"
        placement="bottom-start"
        @visible-change="state => (this.isActive = state)">
        <div>
            <LTooltip
                :always="isActive"
                :label="$t('common.components.dropdownOptions.tooltip')"
                position="left"
                type="white">
                <el-button
                    plain
                    class="dropdown-button"
                    :disabled="disabled"
                    :circle="rounded"
                    :size="size"
                    type="info"
                    icon="el-icon-more">
                </el-button>
            </LTooltip>
        </div>
        <el-dropdown-menu class="py-2">
            <el-dropdown-item
                :key="key"
                v-for="(option, key) in $slots.default">
                <VNode :vnodes="option"></VNode>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
  import VNode from "@/components/Common/VNode";
  
  export default {
    name: "DropdownOptions",
    components: { VNode },
    props: {
      rounded: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "small"
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isActive: false
      };
    }
  };
</script>

<style lang="scss" scoped>
    .dropdown-button {
        @apply flex items-center justify-center;
        width: 30px;
        height: 30px;
    }

    ::v-deep {
        .el-dropdown-menu__item {
            background: none;
            color: #000000;

            &:not(.is-disabled):hover {
                background: none;
                color: #000000;
            }
        }

        .dropdown-content {
            .dropdown-item {
                padding: 0 !important;

                .media {
                    align-items: center;
                }

                & > .button {
                    width: 100%;
                    color: black;
                    display: flex;
                    border-radius: 0;
                    justify-content: left;

                    &:not(:hover) {
                        background-color: white;
                    }

                    &:hover {
                        font-weight: bolder;

                        .button {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
</style>
