<template>
    <div
        class="mr-2"
        @click.stop>
        <el-tooltip
            placement="top"
            :content="$t(`common.preferredCurrency.${ _isVisiblePreferredCurrency }`)">
            <el-checkbox-button
                :value="_isVisiblePreferredCurrency"
                class="checkbox-preferred-currency"
                @input="togglePreferredCurrency">
                {{ currencySign(preferredCurrency) }}
            </el-checkbox-button>
        </el-tooltip>
    </div>
</template>

<script>
  import { currencySign } from "@core/filters";

  export default {
    name: "PreferredCurrencyCheckbox",
    props: {
      isVisiblePreferredCurrency: {
        type: Boolean,
        default: false
      },
      nameLocalStorage: {
        type: String,
        default: null
      }
    },
    created () {
      const preferredCurrency = localStorage.getItem(this.nameLocalStorage);
      if (preferredCurrency) {
        this._isVisiblePreferredCurrency = preferredCurrency === "true";
      }
    },
    computed: {
      preferredCurrency () {
        return this.$store.state.auth.preferredCurrency;
      },

      _isVisiblePreferredCurrency: {
        get () {
          return this.isVisiblePreferredCurrency;
        },
        set (value) {
          this.$emit("isVisiblePreferredCurrency", value);
        }
      }
    },
    methods: {
      currencySign,

      togglePreferredCurrency (value) {
        this._isVisiblePreferredCurrency = value;
        localStorage.setItem(this.nameLocalStorage, value);
      }
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .checkbox-preferred-currency .el-checkbox-button__inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            padding: 0;
            border-radius: 5px;
        }
    }
</style>
