<template>
    <TablePlaceholder :data="advertisers">
        <template slot-scope="{ items, count }">
            <FTable
                :data="items"
                :is-loading="isLoadingTable"
                :current-page="page"
                :per-page.sync="perPage"
                :total="count"
                @update:sort="updateSortings($event)"
                @update:currentPage="updateCurrentPage">
                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.id`)"
                    prop="intId"
                    width="60px"
                    sortable>
                    {{ formatEmptyString(row.intId) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    min-width="140px"
                    :label="$t(`admin.users.advertisers.table.labels.login`)">
                    <div class="is-flex is-align-items-center nowrap">
                        <ColoredStatus
                            :tooltip="$t(`admin.users.advertisers.filters.values.${ row.isApproved ? 'confirmed' : 'unconfirmed' }`)"
                            position="both"
                            tooltip-position="is-bottom"
                            tooltip-type="is-dark"
                            :type="coloredStatus(row.isApproved)">
                            {{ formatEmptyString(row.login) }}

                            <template #after>
                                <LTooltip
                                    v-if="row.activity.isBlocked"
                                    :label="$t(`admin.users.advertisers.filters.values.blocked`)"
                                    position="is-bottom"
                                    type="is-dark">
                                    <b-icon
                                        class="has-text-light"
                                        icon="ban"
                                        size="is-small">
                                    </b-icon>
                                </LTooltip>

                                <LTooltip
                                    v-if="!row.activity.isConfirmed"
                                    :label="$t(`admin.users.advertisers.filters.values.emailUnConfirmed`)"
                                    position="is-bottom"
                                    type="is-dark">
                                    <b-icon
                                        class="has-text-light line-through"
                                        icon="envelope"
                                        size="is-small">
                                    </b-icon>
                                </LTooltip>
                            </template>
                        </ColoredStatus>

                        <LTooltip
                            v-if="row.isApproved"
                            :label="$t(`admin.users.advertisers.table.values.detailsModalLabel`)"
                            class="ml-auto"
                            position="is-bottom"
                            type="is-info">
                            <b-tag
                                class="is-clickable"
                                type="is-info is-light"
                                disabled
                                @click.native="advertisersTableDetailsModalOpen(row)">
                                <b-icon
                                    icon="signal"
                                    size="is-small"></b-icon>
                            </b-tag>
                        </LTooltip>
                    </div>
                </el-table-column>

                <!-- <el-table-column v-slot="{ row }" :label="$t(`admin.users.advertisers.table.labels.offers`)">
                      {{ formatEmptyString() }}
                  </el-table-column>

                  <el-table-column v-slot="{ row }" :label="$t(`admin.users.advertisers.table.labels.allPaymentsSum`)">
                      {{ formatEmptyString() }}
                  </el-table-column>

                  <el-table-column v-slot="{ row }" :label="$t(`admin.users.advertisers.table.labels.lastPayment`)">
                      {{ formatEmptyString() }}
                  </el-table-column> -->

                <el-table-column
                    prop="balance"
                    class="el-table-column"
                    sortable>
                    <template #header>
                        <div class="flex is-align-items-center">
                            <PreferredCurrencyCheckbox
                                :is-visible-preferred-currency="isVisiblePreferredCurrency"
                                name-local-storage="isVisiblePreferredCurrencyAdvertisersBalance"
                                @isVisiblePreferredCurrency="updateIsVisiblePreferredCurrency">
                            </PreferredCurrencyCheckbox>
                            {{ $t(`admin.users.webmasters.table.labels.balance`) }}
                        </div>
                    </template>
                    <template #default="props">
                        <InlineLoader
                            v-if="!props.row.balance"
                            active></InlineLoader>
                        <template v-else>
                            <span
                                :key="idx"
                                v-for="(item, idx) in props.row.balance">
                                <template
                                    v-if="preferredCurrency === props.row.balance[idx].balance.currency
                                        && isVisiblePreferredCurrency">
                                    {{ formatCurrency(
                                        toFixed(props.row.balance[idx].balance.value, 2),
                                        props.row.balance[idx].balance.currency
                                    ) }}
                                </template>
                                <template v-if="!isVisiblePreferredCurrency">
                                    {{ formatCurrency(toFixed(item.balance.value, 2), item.balance.currency) }} <br>
                                </template>
                            </span>
                        </template>
                    </template>
                    <!--                    <InlineLoader-->
                    <!--                        v-if="!row.balance"-->
                    <!--                        active></InlineLoader>-->
                    <!--                    <template v-else>-->
                    <!--                        <span-->
                    <!--                            :key="idx"-->
                    <!--                            v-for="(item, idx) in row.balance">-->
                    <!--                            {{ formatCurrency(toFixed(item.balance.value, 2), item.balance.currency) }} <br>-->
                    <!--                        </span>-->
                    <!--                    </template>-->
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.offersAmount`)"
                    prop="offersAmount"
                    sortable>
                    {{ formatEmptyString(row.offersAmount) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.totalPayments`)"
                    prop="totalPayments"
                    sortable>
                    <span
                        :key="idx"
                        v-for="(item, idx) in sortByPreferredCurrency(row.totalPayments)">
                        {{ formatCurrency(toFixed(item.value, 2), item.currency) }} <br>
                    </span>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.lastTransactionDate`)"
                    prop="lastTransactionDate"
                    sortable>
                    {{ formatEmptyString(moment(row.lastTransactionDate)) }}
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.penalties`)">
                    <span
                        v-if="row.isFineDebt"
                        class="penalties">
                        {{ row.fineDebtPercent }}%/{{ $t(
                            `admin.users.advertisers.table.values.penalties`,
                            { penalties: row.fineDebtDays }
                        ) }}
                    </span>
                    <template v-else>
                        -
                    </template>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.email`)">
                    <EmailOutput :email="row.contact.email"></EmailOutput>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.telegram`)">
                    <TelegramOutput :telegram="row.contact.telegram"></TelegramOutput>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.regDate`)"
                    prop="registeredAt"
                    sortable>
                    {{ formatEmptyString(moment(row.activity.registeredAt)) }}
                </el-table-column>


                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.advertisers.table.labels.admin`)">
                    <UserOutput :user="row.admin"></UserOutput>
                </el-table-column>

                <!-- <el-table-column v-slot="{ row }" :label="$t(`admin.users.advertisers.table.labels.comment`)">
                                      {{ formatEmptyString(row.comment) }}
                                  </el-table-column> -->

                <el-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.table.labels.comment`)">
                    <div class="is-flex is-align-items-center">
                        <ShowMore
                            :data="row.comment"
                            :max-string="50"></ShowMore>
                        <DropdownComment
                            :ref="`dropdown-${ row.id }`"
                            :is-loading="loading.updateComment"
                            :value="row.comment"
                            @update="changeComment($event, row.id)">
                        </DropdownComment>
                    </div>
                </el-table-column>

                <el-table-column
                    v-slot="{ row }"
                    class-name="options">
                    <DropdownOptions rounded>
                        <Permissions :permissions="['ADVERTISERS.EDIT.ALL', 'ADVERTISERS.EDIT.OWN']">
                            <DropdownOption
                                icon="square-edit"
                                type="is-warning is-light"
                                @click="onEditAdvertiser(row.id)">
                                {{ $t(`admin.users.advertisers.table.buttons.edit`) }}
                            </DropdownOption>
                        </Permissions>

                        <DropdownOption
                            v-if="hasBindAll"
                            icon="account-plus"
                            type="is-success is-light"
                            @click="onToggleBind(row)">
                            {{ $t("admin.users.common.table.buttons.changeManager") }}
                        </DropdownOption>


                        <Permissions :permissions="['ADVERTISERS.BIND.FREE']">
                            <DropdownOption
                                v-if="!row.admin"
                                icon="account-plus"
                                type="is-success is-light"
                                @click="bindToSelf(row)">
                                {{ $t('admin.users.webmasters.modal.bindUser.body.labels.bindToSelf') }}
                            </DropdownOption>
                        </Permissions>

                        <Permissions
                            :permissions="[
                                'ADVERTISERS.BLOCK.ALL',
                                'ADVERTISERS.BLOCK.OWN',
                                'ADVERTISERS.UNBLOCK.ALL',
                                'ADVERTISERS.UNBLOCK.OWN'
                            ]"
                            :validators="advertiserOwnValidators(row)">
                            <DropdownOption
                                :icon="blockButtonIcon(row.activity.isBlocked)"
                                :type="blockButtonType(row.activity.isBlocked)"
                                @click="onToggleBlock(row)">
                                <template v-if="row.activity.isBlocked">
                                    {{ $t(`admin.users.webmasters.table.buttons.unblock`) }}
                                </template>
                                <template v-else>
                                    {{ $t(`admin.users.webmasters.table.buttons.block`) }}
                                </template>
                            </DropdownOption>
                        </Permissions>
                        
                        <DropdownOption
                            icon="hand-holding-usd"
                            type="is-warning is-light"
                            @click="() => {
                                advertiserUserId = row.id;
                                paymentModal = true;
                            }">
                            {{ $t(`admin.users.advertisers.table.buttons.createPaymentAdvertiser`) }}
                        </DropdownOption>

                        <DropdownOption
                            v-if="!row.activity.isConfirmed"
                            icon="envelope"
                            type="is-success is-light"
                            @click="resendEmailByAdmin(row.id)">
                            {{ $t(`common.mailConfirmation.resendEmail.resend`) }}
                        </DropdownOption>

                        <Permissions
                            :permissions="['ADVERTISERS.VIEW_HISTORY.ALL', 'ADVERTISERS.VIEW_HISTORY.OWN']"
                            :validators="advertiserOwnValidators(row)">
                            <DropdownOption
                                icon="clock"
                                @click="onShowHistory(row)">
                                {{ $t(`admin.users.advertisers.table.buttons.actionsHistory`) }}
                            </DropdownOption>
                        </Permissions>
                    </DropdownOptions>

                    <Permissions
                        v-if="row.isApproved"
                        :permissions="['ADVERTISERS.LOGIN.ALL', 'ADVERTISERS.LOGIN.OWN']"
                        :validators="advertiserOwnValidators(row)">
                        <LTooltip
                            :label="$t(`admin.users.advertisers.table.buttons.signIn`)"
                            position="is-left"
                            type="is-success">
                            <b-button
                                :disabled="row.activity.isBlocked"
                                icon-left="sign-in-alt"
                                icon-pack="fas"
                                rounded
                                size="is-small"
                                type="is-success is-light"
                                @click="singInAsAdvertiser(row.id)">
                            </b-button>
                        </LTooltip>
                    </Permissions>

                    <Permissions
                        v-else
                        :permissions="['ADVERTISERS.APPROVE.ALL', 'ADVERTISERS.APPROVE.OWN']"
                        :validators="advertiserOwnValidators(row)">
                        <LTooltip
                            :label="$t(`admin.users.advertisers.table.buttons.approved`)"
                            position="is-left"
                            type="is-success">
                            <b-button
                                icon-left="check-circle"
                                icon-pack="far"
                                rounded
                                size="is-small"
                                type="is-success is-light"
                                @click="onApproved(row)">
                            </b-button>
                        </LTooltip>
                    </Permissions>
                </el-table-column>
            </FTable>

            <AdvertisersTableDetailsModal
                :is-active.sync="detailsModal"
                :advertiser="detailsModalData">
            </AdvertisersTableDetailsModal>
    
            <FinancesIncomesCreatePaymentModal
                :is-active.sync="paymentModal"
                :user="advertiserUserId">
            </FinancesIncomesCreatePaymentModal>
    
            <ModalBindUserToAdmin
                v-if="isBindModalVisible"
                :admin="admin"
                :delete-method="DELETE_ADVERTISER_PERSONAL_MANAGER"
                :is-active.sync="isBindModalVisible"
                :is-loading="isBindLoading"
                :method="ADD_ADVERTISER_PERSONAL_MANAGER"
                :user-id="advertiserUserId"
                :user-login="advertiserUserLogin"
                role="advertisers"
                @update="GET_ADVERTISERS_WITH_BALANCE()">
            </ModalBindUserToAdmin>
    
            <ModalBox :is-active.sync="isHistoryDialogVisible">
                <template #header>
                    <h5 class="title is-5">
                        {{ $t(`admin.users.advertisers.modal.header.historyTitle`) }}
                    </h5>
                </template>
        
                <TablePlaceholder :data="historyAdvertisers">
                    <template slot-scope="{ items: items_, count: count_ }">
                        <FTable
                            :data="items_"
                            :total="count_">
                            <el-table-column
                                v-slot="{ row }"
                                :label="$t(`admin.users.advertisers.modal.body.table.labels.dateTime`)">
                                {{ formatEmptyString(moment(row.createdAt)) }}
                            </el-table-column>
                    
                            <el-table-column :label="$t(`admin.users.advertisers.modal.body.table.labels.action`)">
                                {{ formatEmptyString() }}
                            </el-table-column>
                    
                            <el-table-column
                                v-slot="{ row }"
                                :label="$t(`admin.users.advertisers.modal.body.table.labels.note`)">
                                {{ formatEmptyString(row.action) }}
                            </el-table-column>
                        </FTable>
                    </template>
                </TablePlaceholder>
            </ModalBox>
    
            <CallCenters
                :advertiser-id="advertiserUserId"
                :is-active.sync="isCallCentersVisible">
            </CallCenters>
        </template>
    </TablePlaceholder>
</template>

<script>
  import PreferredCurrencyCheckbox from "@/components/Common/PreferredCurrencyCheckbox";
  import ModalBindUserToAdmin from "@/components/Admin/Users/ModalBindUserToAdmin";
  import TableOptionsMixin from "@/components/Admin/Users/TableOptionsMixin";
  import DropdownComment from "@/components/Common/DropdownComment";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import EmailOutput from "@/components/Common/EmailOutput";
  import InlineLoader from "@/components/Common/InlineLoader";
  import ModalBox from "@/components/Common/ModalBox";
  import Permissions from "@/components/Common/Permissions";
  import ShowMore from "@/components/Common/ShowMore";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TelegramOutput from "@/components/Common/TelegramOutput";
  import UserOutput from "@/components/Common/UserOutput";
  import { TableVuex } from "@core/mixins";
  import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
  import { hasPermissions } from "@core/mixins/permissions";
  import {
    ADD_ADVERTISER_PERSONAL_MANAGER,
    ADD_APPROVED_ADVERTISER,
    ADMIN_LOGIN_AS_ADVERTISER,
    BLOCK_ADVERTISER,
    DELETE_ADVERTISER_PERSONAL_MANAGER,
    EDIT_ADVERTISER_ADMIN_COMMENT,
    GET_ADVERTISERS,
    GET_ADVERTISERS_WITH_BALANCE,
    GET_HISTORY_ADVERTISER,
    RESEND_EMAIL_BY_ADMIN,
    UNBLOCK_ADVERTISER
  } from "@core/store/action-constants";
  import {
    SET_ADVERTISERS_EDIT_ID,
    SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE,
    UPDATE_ADVERTISER_EDITABLE_COMMENT,
    UPDATE_ADVERTISERS_PAGINATION,
    UPDATE_ADVERTISERS_SORTS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import FinancesIncomesCreatePaymentModal from "@/components/Admin/Finances/FinancesIncomes/FinancesIncomesCreatePaymentModal";
  import AdvertisersTableDetailsModal from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal";
  import CallCenters from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCenters";
  import FTable from "../../../Common/FTable";

  export default {
    name: "AdvertisersTable",
    mixins: [TableOptionsMixin, TableVuex],
    components: {
      CallCenters,
      ColoredStatus,
      ModalBindUserToAdmin,
      Permissions,
      FinancesIncomesCreatePaymentModal,
      AdvertisersTableDetailsModal,
      TablePlaceholder,
      ModalBox,
      DropdownOptions,
      DropdownOption,
      UserOutput,
      ShowMore,
      PreferredCurrencyCheckbox,
      DropdownComment,
      InlineLoader,
      EmailOutput,
      TelegramOutput,
      FTable
    },

    data: () => ({
      isVisiblePreferredCurrency: false,
      isHistoryDialogVisible: false,
      isBindModalVisible: false,
      isCallCentersVisible: false,
      advertiserUserId: null,
      advertiserUserLogin: null,
      admin: null,
      paymentModal: false,
      detailsModal: false,
      detailsModalData: null,
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small"
    }),
    computed: {
      ...mapState("admin/users/advertisers", [
        "historyAdvertisers"
      ]),

      ...mapFields("admin/users/advertisers", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_ADVERTISERS_PAGINATION
      }),

      ...mapState("admin", {
        advertisers: (state) => state.users.advertisers.advertisers,
        historyAdvertisers: ({ users }) => users.advertisers.historyAdvertisers,
        adminUserIdState: state => state.profile.user.id
      }),

      loading () {
        return {
          updateComment: this.$wait(`admin/users/advertisers/${ EDIT_ADVERTISER_ADMIN_COMMENT }`)
        };
      },

      userPermissions () {
        return (this.$store || { getters: {} }).getters.permissions;
      },

      hasBindAll () {
        return !hasPermissions(["ADVERTISERS.BIND.FREE"], this.userPermissions) &&
          hasPermissions(["ADVERTISERS.BIND.ALL"], this.userPermissions);
      },

      isLoadingTable () {
        return this.$wait(`admin/users/advertisers/${ GET_ADVERTISERS }`);
      },

      isPaginated () {
        return this.advertisers.count > this.perPage;
      },

      isBindLoading () {
        return this.$wait(`admin/users/advertisers/${ ADD_ADVERTISER_PERSONAL_MANAGER }`) ||
          this.$wait(`admin/users/advertisers/${ DELETE_ADVERTISER_PERSONAL_MANAGER }`);
      },

      preferredCurrency () {
        return this.$store.state.auth.preferredCurrency;
      }
    },
    methods: {
      sortByPreferredCurrency,
        
      ...mapActions([
        ADMIN_LOGIN_AS_ADVERTISER
      ]),

      ...mapActions("admin/users/advertisers", [
        ADD_APPROVED_ADVERTISER,
        ADD_ADVERTISER_PERSONAL_MANAGER,
        DELETE_ADVERTISER_PERSONAL_MANAGER,
        BLOCK_ADVERTISER,
        UNBLOCK_ADVERTISER,
        GET_HISTORY_ADVERTISER,
        RESEND_EMAIL_BY_ADMIN,
        GET_ADVERTISERS,
        GET_ADVERTISERS_WITH_BALANCE,
        EDIT_ADVERTISER_ADMIN_COMMENT,
        UPDATE_ADVERTISER_EDITABLE_COMMENT
      ]),

      ...mapMutations("admin/users/advertisers/edit", [
        SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE,
        SET_ADVERTISERS_EDIT_ID
      ]),

      ...mapMutations("admin/users/advertisers", [
        UPDATE_ADVERTISERS_SORTS
      ]),

      bindToSelf ({ id, login }) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.users.advertisers.confirm.bindToSelf.title"),
          message: this.$t("admin.users.advertisers.confirm.bindToSelf.message"),
          confirmText: this.$t("admin.users.advertisers.confirm.bindToSelf.confirm"),
          cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
          type: "is-success is-light",
          onConfirm: async () => {
            try {
              await this.ADD_ADVERTISER_PERSONAL_MANAGER({ userId: id, adminId: this.adminUserIdState });
              this.toastSuccess(this.$t("admin.users.advertisers.messages.bind.success", { login }));
              this.GET_ADVERTISERS_WITH_BALANCE();
            } catch (err) {
              this.toastFail(this.$t("admin.users.advertisers.messages.bind.fail", { login }), err);
            }
          }
        });
      },
  
      advertiserOwnValidators (advertiser) {
        const func = () => advertiser.isBindedToCurrentAdmin;

        return {
          "ADVERTISERS.LOGIN.OWN": func,
          "ADVERTISERS.APPROVE.OWN": func,
          "ADVERTISERS.BLOCK.OWN": func,
          "ADVERTISERS.UNBLOCK.OWN": func,
          "ADVERTISERS.VIEW_HISTORY.OWN": func
        };
      },

      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },

      onApproved ({ id, login }) {
        if (id) {
          this.$buefy.dialog.confirm({
            title: this.$t("admin.users.advertisers.confirm.approved"),
            confirmText: this.$t("admin.users.advertisers.confirm.confirmButtonText"),
            cancelText: this.$t("admin.users.advertisers.confirm.cancelButtonText"),
            type: "is-info is-light",
            onConfirm: async () => {
              try {
                await this.ADD_APPROVED_ADVERTISER(id);
                this.toastSuccess(this.$t("admin.users.advertisers.messages.approve.success", { advertiserLogin: login }));
                this.emitUpdate();
              } catch (err) {
                this.toastFail(this.$t("admin.users.advertisers.messages.approve.fail", { advertiserLogin: login }), err);
              }
            }
          });
        }
      },

      onToggleBlock ({ id, login, activity }) {
        if (id) {
          const blocked = activity.isBlocked;
          const title = blocked
            ? this.$t("admin.users.advertisers.confirm.isUnBlocked")
            : this.$t("admin.users.advertisers.confirm.isBlocked");

          this.$buefy.dialog.confirm({
            title,
            confirmText: this.$t("admin.users.advertisers.confirm.confirmButtonText"),
            cancelText: this.$t("admin.users.advertisers.confirm.cancelButtonText"),
            type: "is-info is-light",
            onConfirm: async () => {
              if (blocked) {
                try {
                  await this.UNBLOCK_ADVERTISER(id);
                  this.toastSuccess(this.$t("admin.users.advertisers.messages.unblock.success", { advertiserLogin: login }));
                  this.emitUpdate();
                } catch (err) {
                  this.toastFail(this.$t("admin.users.advertisers.messages.unblock.fail", { advertiserLogin: login }), err);
                }
              } else {
                try {
                  await this.BLOCK_ADVERTISER(id);
                  this.toastSuccess(this.$t("admin.users.advertisers.messages.block.success", { advertiserLogin: login }));
                  this.emitUpdate();
                } catch (err) {
                  this.toastFail(this.$t("admin.users.advertisers.messages.block.fail", { advertiserLogin: login }), err);
                }
              }
            }
          });
        }
      },

      onToggleBind ({ id, login, admin }) {
        this.advertiserUserId = id;
        this.advertiserUserLogin = login;
        this.admin = admin === null ? [] : [admin];
        this.isBindModalVisible = true;
      },

      onEditAdvertiser (id) {
        this.SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE(true);
        this.SET_ADVERTISERS_EDIT_ID(id);
      },

      singInAsAdvertiser (id) {
        this.ADMIN_LOGIN_AS_ADVERTISER(id);
      },

      onShowHistory ({ id }) {
        this.GET_HISTORY_ADVERTISER(id);
        this.isHistoryDialogVisible = true;
      },

      onShowCallCenters (id) {
        this.isCallCentersVisible = true;
        this.advertiserUserId = id;
      },

      advertisersTableDetailsModalOpen (value) {
        this.detailsModal = true;
        this.detailsModalData = value;
      },

      async resendEmailByAdmin (id) {
        try {
          await this.RESEND_EMAIL_BY_ADMIN(id);

          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.success"),
            type: "is-success"
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.fail"),
            type: "is-danger"
          });
        }
      },

      updateSortings ({ sort, order }) {
        if (sort && order) {
          this.UPDATE_ADVERTISERS_SORTS({
            sort,
            order
          });
          this.GET_ADVERTISERS_WITH_BALANCE();
        }
      },

      updateCurrentPage (page) {
        this.page = page;
      },

      async changeComment (comment, userId) {
        try {
          this.UPDATE_ADVERTISER_EDITABLE_COMMENT({ comment, userId });
          await this.EDIT_ADVERTISER_ADMIN_COMMENT();

          this.GET_ADVERTISERS_WITH_BALANCE();
          this.$refs[`dropdown-${ userId }`].toggle();
          this.$buefy.toast.open({
            message: this.$t("admin.efficiency.plans.messages.changeCommentSuccess"),
            type: "is-success"
          });
        } catch (_) {
          this.$buefy.toast.open({
            message: this.$t("admin.efficiency.plans.messages.changeCommentError"),
            type: "is-warning"
          });
        }
      },
      
      emitUpdate () {
        this.GET_ADVERTISERS_WITH_BALANCE();
      },

      updateIsVisiblePreferredCurrency (value) {
        this.isVisiblePreferredCurrency = value;
      }
    },
    watch: {
      detailsModal (value) {
        if (value === false) this.detailsModalData = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .line-through {
        position: relative;

        &:after {
            content: "";
            top: 0;
            left: 0;
            width: 4px;
            height: 120%;
            position: absolute;
            transform: translate(5px, -2px) rotateZ(-45deg);
            background-image: linear-gradient(90deg, #B5B5B5 50%, white 50%);
        }
    }

    .penalties {
        white-space: nowrap;
    }

    .comment-form {
      width: 300px;
    }
</style>
