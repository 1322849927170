<template>
    <ModalBox
        :is-active.sync="isModalActive"
        @submit="validate">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.users.common.modal.password.header.title") }}
            </h5>
        </template>
        <el-form :model="{ password }">
            <ValidationProvider
                ref="validationProvider"
                rules="minStringLength">
                <EditUserPasswordField
                    v-model="_password"
                    prop="password"
                    :label="$t('admin.users.common.modal.password.body.password')"
                    :placeholder="$t('admin.users.common.modal.password.body.password')">
                </EditUserPasswordField>
            </ValidationProvider>
        </el-form>
        <template #footer>
            <div class="container">
                <div class="columns is-pulled-right">
                    <div class="buttons column is-4">
                        <b-button
                            class="is-success is-light"
                            native-type="submit">
                            {{ $t('common.buttons.save') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
        <b-loading
            v-model="isLoading"
            :is-full-page="false">
        </b-loading>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import { Modal } from "@core/mixins/modal";
  import { extend, ValidationProvider } from "vee-validate";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";

  export default {
    name: "PasswordModal",
    
    mixins: [Modal],
    
    components: {
      ModalBox,
      ValidationProvider,
      EditUserPasswordField
    },

    props: {
      password: {
        type: String,
        default: null
      }
    },

    mounted () {
      extend("minStringLength", value => {
        if (value && `${ value }`.length > 5) {
          return true;
        }

        return this.$t("admin.users.common.modal.password.messages.minLength");
      });
    },

    computed: {
      _password: {
        get () {
          return this.password;
        },

        set (value) {
          this.$emit("update:password", value);
        }
      }
    },

    methods: {
      async validate () {
        const { errors } = await this.$refs.validationProvider.validate();

        if (errors.length) {
          this.$buefy.toast.open({
            message: errors[0],
            type: "is-danger"
          });
        } else {
          this.$emit("submit");
        }
      }
    }
  };
</script>

<style></style>
