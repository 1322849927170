<template>
    <div>
        <RatesFilters :advertiser="advertiser"></RatesFilters>
        <RatesTable></RatesTable>
    </div>
</template>

<script>
  import RatesFilters from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/Rates/RatesFilters";
  import RatesTable from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/Rates/RatesTable";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_ADVERTISER_DETAIL_DATA } from "@core/store/mutation-constants";

  export default {
    name: "AdvertisersTableDetailsModalRates",

    components: {
      RatesFilters,
      RatesTable
    },

    props: {
      advertiser: {
        type: Object,
        default: null
      }
    },

    created () {
      this.advertiserId = this.advertiser.id;
    },
    
    computed: {
      ...mapFields("admin/users/advertisers/detail", {
        fields: ["advertiserId"],
        base: "data",
        action: UPDATE_ADVERTISER_DETAIL_DATA
      })
    }
  };
</script>

<style scoped>

</style>
