// @vue/components
export default {
  methods: {
    bindButtonType (isBoundToCurrentAdmin) {
      return isBoundToCurrentAdmin ? "is-danger is-light" : "is-success is-light";
    },
    
    bindButtonIcon (isBoundToCurrentAdmin) {
      return isBoundToCurrentAdmin ? "user-minus" : "user-plus";
    },
    
    blockButtonType (blocked) {
      return blocked ? "is-warning is-light" : "is-danger is-light";
    },
    
    blockButtonIcon (blocked) {
      return blocked ? "unlock" : "lock";
    },
    
    apiButtonType (isEnableApi) {
      return isEnableApi ? "is-danger is-light" : "is-success is-light";
    }
  }
};
