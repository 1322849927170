<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :on-cancel="() => close()">
        <template #header>
            <div class="columns is-vcentered">
                <h5 class="subtitle is-narrow column">
                    {{ $t("common.entity.callCenters.modals.moveGap.title") }}
                </h5>
            </div>
        </template>
        <div>
            <b-notification
                :active="isNextMasterDay"
                :closable="false"
                type="is-warning is-light">
                <span>
                    {{ $t("common.entity.callCenters.modals.moveGap.notification") }}
                </span>
            </b-notification>
            <span>
                {{ this.$t("common.entity.callCenters.modals.moveGap.message") }}

                <strong>
                    {{ this.getTime(time.timeStart) + " &ndash; 24:00 /" + " 00:00 &ndash; " + this.getTime(time.timeFinish)
                    }}
                </strong> <br>

                {{ this.$t("common.entity.callCenters.modals.moveGap.confirm") }}
            </span>
        </div>

        <template #footer>
            <div class="container">
                <div class="columns is-pulled-right pr-5 pb-4 is-variable is-1">
                    <div class="column">
                        <b-button
                            class="is-light-button"
                            @click="close">
                            {{ $t("common.entity.callCenters.buttons.cancelButtonText") }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            :class="['is-light', isNextMasterDay ? 'is-warning' : 'is-info']"
                            @click="confirm">
                            {{ $t("common.entity.callCenters.modals.moveGap.confirmButtonText") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import { CallCenters, Modal } from "@core/mixins";
  
  export default {
    name: "AddGapNextDayModal",
    mixins: [Modal, CallCenters],
    components: {
      ModalBox
    },
    props: {
      time: {
        type: Object,
        default: () => ({})
      },
      isNextMasterDay: {
        type: Boolean,
        default: null
      }
    },

    data () {
      return {

      };
    },

    methods: {
      close () {
        this.$emit("close");
        this.closeModal();
      },

      confirm () {
        this.$emit("confirm");
        this.closeModal();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .modal::v-deep {
      .animation-content {
        max-width: 960px !important;
      }

      .modal-card {
        max-width: 650px !important;

        .modal-card-foot {
          padding: 10px;
        }
      }
    }
</style>
