<template>
    <th
        v-if="isHeader"
        :class="rowField.titleClass"
        @click="onColumnHeaderClicked(rowField, $event)">
        <!--        rowData: {{ rowData }}-->
        <!--        rowIndex: {{ rowIndex }}-->
        <!--        rowField: {{ rowField }}-->
        <!--        isHeader: {{ isHeader }}-->
        <!--        title: {{ title }}-->
        <div v-html="title"></div>
    </th>
    <td v-else>
        <UserOutput :user="user"></UserOutput>
    </td>
</template>

<script>
  import UserOutput from "@/components/Common/UserOutput";
  import { StatisticTableView } from "@core/mixins";
  
  export default {
    name: "StatisticTableViewUserOutput",
    mixins: [StatisticTableView],
    components: { UserOutput },
    
    computed: {
      user () {
        const {
          rowData,
          rowField: { label }
        } = this;
        
        return this.formatter(this.getMethodByPath(rowData, label));
      }
    }
  };
</script>

<style lang="scss" scoped>
    th {
        padding: 0.8em 0.5em;

        & > div {
            display: flex;
            white-space: nowrap;

            ::v-deep {
                i {
                    margin-left: 0.5em;
                }
            }
        }
    }
</style>
