<template functional>
    <span v-if="props.telegram">
        <a :href="props.domain + $options.methods.clearLogin(props.telegram)">
            {{ $options.methods.formatEmptyString(props.telegram) }}
        </a>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
  
  export default {
    name: "TelegramOutput",
    
    props: {
      telegram: {
        type: String,
        default: null
      },
      
      domain: {
        type: String,
        default: "tg://resolve?domain="
      }
    },
    
    methods: {
      formatEmptyString,
      
      clearLogin (login) {
        return login.startsWith("@") ? login.replace(/^@/, "") : login;
      }
    }
  };
</script>

<style lang="scss" scoped>
    a {
        color: #000000;
        text-decoration: underline dotted;
    }
</style>
